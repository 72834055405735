import clsx from "clsx"
import React, { DetailedHTMLProps, FC, InputHTMLAttributes } from "react"

type NativeTextareaProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>

const Textarea: FC<
  {
    label: string
    textareaClassName: string
  } & NativeTextareaProps
> = ({ label, textareaClassName, className, ...props }) => (
  <div
    className={clsx(
      "border border-gray-300 border-opacity-60 rounded-md px-3 py-2 shadow-sm focus-within:ring-2 focus-within:ring-romeo-text bg-white",
      className
    )}
  >
    <label htmlFor="name" className="block text-xs font-medium text-gray-900">
      {label}
    </label>
    <textarea
      className={clsx(
        "block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm",
        textareaClassName
      )}
      {...props}
    />
  </div>
)

export default Textarea
