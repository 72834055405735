import React from "react"
import dayjs from "dayjs"
import { StaticImage } from "gatsby-plugin-image"
import Footer from "$components/footer"
import DesktopFrame from "$components/desktop-frame"
import Messages from "$components/messages"
import Metadata from "$components/metadata"

const dueDate = dayjs("2022-01-22 11:00")
const daysLeft = dueDate.startOf("day").diff(dayjs().startOf("day"), "day")

const Home = () => (
  <div className={"relative bg-romeo-background font-system text-romeo-text"}>
    <Metadata />
    <DesktopFrame dueDate={dueDate} />

    <img
      className={"absolute top-28 md:top-96 right-0 left-0 bottom-0 w-full"}
      src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1003%26quot%3b)' fill='none'%3e%3cpath d='M -945.8968927247779%2c278 C -849.9%2c312.6 -657.9%2c455.2 -465.89689272477796%2c451 C -273.9%2c446.8 -177.9%2c251.8 14.103107275222044%2c257 C 206.1%2c262.2 302.1%2c495.4 494.10310727522204%2c477 C 686.1%2c458.6 784.92%2c180 974.1031072752221%2c165 C 1163.28%2c150 1346.82%2c354.6 1440%2c402' stroke='rgba(205%2c 201%2c 197%2c 1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M -537.4188180458387%2c318 C -441.42%2c291.4 -249.42%2c179.2 -57.41881804583873%2c185 C 134.58%2c190.8 230.58%2c355.2 422.58118195416125%2c347 C 614.58%2c338.8 710.58%2c151.4 902.5811819541613%2c144 C 1094.58%2c136.6 1275.1%2c289.2 1382.5811819541614%2c310 C 1490.06%2c330.8 1428.52%2c260.4 1440%2c248' stroke='rgba(205%2c 201%2c 197%2c 1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M -125.28081539758534%2c192 C -29.28%2c233.2 162.72%2c391.6 354.71918460241466%2c398 C 546.72%2c404.4 642.72%2c236.4 834.7191846024147%2c224 C 1026.72%2c211.6 1122.72%2c349.6 1314.7191846024148%2c336 C 1506.72%2c322.4 1769.66%2c145.2 1794.7191846024148%2c156 C 1819.78%2c166.8 1510.94%2c343.2 1440%2c390' stroke='rgba(205%2c 201%2c 197%2c 1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M -351.9561101655452%2c91 C -255.96%2c170 -63.96%2c489.8 128.04388983445477%2c486 C 320.04%2c482.2 416.04%2c81.8 608.0438898344548%2c72 C 800.04%2c62.2 896.04%2c396.2 1088.0438898344548%2c437 C 1280.04%2c477.8 1497.65%2c306 1568.0438898344548%2c276 C 1638.44%2c246 1465.61%2c284.8 1440%2c287' stroke='rgba(205%2c 201%2c 197%2c 1)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1003'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e"
      alt={""}
    />

    <main className={"relative xl:px-28 pb-16"}>
      <p className="text-xl text-romeo-text font-bold font-romeo text-center mx-auto px-8 pt-8 block md:hidden opacity-80">
        Wedding Announcement
      </p>
      <h1 className="text-5xl text-romeo-text font-bold font-romeo text-center mx-auto px-8 block md:hidden mb-4 flex items-center justify-center">
        Alde <span className={"-ml-3 opacity-20 text-7xl -mr-4"}>&</span> Maria
      </h1>

      <div className={"max-w-7xl mx-auto"}>
        <div className={"md:flex md:flex-row-reverse"}>
          <div className={`relative p-8 md:pt-16`}>
            <StaticImage
              src="../images/beach-4.jpg"
              alt={""}
              placeholder="blurred"
              layout={"constrained"}
              width={700}
              height={1050}
              objectPosition={"bottom right"}
            />
          </div>

          <div className="flex flex-col items-center px-16 mx-auto hidden md:flex md:mt-64">
            <p className="pb-4 text-2xl font-romeo text-romeo-text text-center">
              Wedding Announcement
            </p>

            <h1 className="text-7xl text-romeo-text font-bold font-romeo text-center hidden md:block">
              <div className={"mr-44"}>Alde</div>
              <div className={"text-9xl opacity-20 -mb-10 -mt-14"}>&</div>
              <div className={"ml-48"}>Maria</div>
            </h1>
          </div>
        </div>
      </div>

      <div className={"flex flex-col md:flex-row max-w-7xl mx-auto p-8"}>
        <div className={"prose max-w-md"}>
          <p className={"text-xl font-romeo"}>
            "I'm selfish, impatient and a little insecure. I make mistakes, I am
            out of control and at times hard to handle. But if you can't handle
            me at my worst, then you sure as hell don't deserve me at my best."
          </p>
          <p className={"font-romeo opacity-80 text-lg"}>- Marilyn Monroe</p>

          <p>
            Mengingat situasi yang belum memungkinkan di masa pandemi ini, kami
            sangat menghargai apabila Bapak/Ibu berkenan mengikuti misa
            pernikahan kami melalui livestream youtube dengan link dibawah.
          </p>
          <a
            href={"https://youtu.be/jXW_OGzj_5k"}
            className="px-4 py-3 rounded-lg border-2 hover:bg-romeo-text hover:text-white border-romeo-text text-romeo-text text-sm inline-block ml-auto font-bold hover:ring-2 ring-romeo-text ring-offset-2 ring-offset-romeo-background transition duration-200 no-underline"
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            Buka Livestream
          </a>
        </div>

        <div className={"pb-8 ml-auto mt-16 md:mt-64"}>
          <div>
            <p className={"pb-4 text-romeo-text"}>Date & Time</p>
            <p className="font-bold text-4xl font-romeo text-romeo-text">
              {dueDate.format("dddd,")}
            </p>
            <p className="font-bold text-4xl font-romeo text-romeo-text">
              {dueDate.format("DD MMMM YYYY")}
            </p>
            <p className="font-bold text-4xl font-romeo text-romeo-text">
              {dueDate.format("hh:mm")}
            </p>
          </div>

          <div className={"mt-16"}>
            <div className={"pb-4 text-romeo-text"}>Location</div>
            <div className="font-bold text-4xl font-romeo text-romeo-text ">
              Kapel Desa Putra
            </div>
            <div className="font-bold text-4xl font-romeo text-romeo-text">
              Jagakarsa, Jakarta Selatan
            </div>
            <a
              href="https://goo.gl/maps/efHgahBcXLkn2oXh6"
              className="px-4 py-3 rounded-lg bg-romeo-text text-white font-romeo inline-block ml-auto font-bold mt-6 hover:ring-2 ring-romeo-text ring-offset-2 ring-offset-romeo-background transition duration-200"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              Buka di Peta
            </a>
          </div>
        </div>
      </div>

      <div className={"2xl:-mt-64"}>
        <StaticImage
          src="../images/bridge.jpg"
          alt={""}
          placeholder="blurred"
          layout={"constrained"}
          width={700}
          height={1050}
          objectPosition={"bottom right"}
          className={"m-8"}
        />
      </div>

      <div className={"flex 2xl:-mt-80 ml-8"}>
        <StaticImage
          src="../images/beach-1.jpg"
          alt={""}
          placeholder="blurred"
          layout={"constrained"}
          width={700}
          height={1050}
          objectPosition={"bottom right"}
          className={"m-8 ml-auto"}
        />
      </div>
    </main>

    <Messages />
    <Footer
      daysLeft={daysLeft}
      time={dueDate.format("HH:mm")}
      mapLink={"https://goo.gl/maps/efHgahBcXLkn2oXh6"}
      livestreamLink={"https://youtu.be/jXW_OGzj_5k"}
    />
  </div>
)

export default Home
