import { postMessages, useMessages } from "$lib/messages"
import TextField from "$components/text-field"
import React, { useState } from "react"
import Textarea from "$components/textarea"
import Button from "$components/button"
import { useSWRConfig } from "swr"
import dayjs from "dayjs"

const Messages = () => {
  const { mutate } = useSWRConfig()
  const { data: messages } = useMessages()

  const [name, setName] = useState("")
  const [message, setMessage] = useState("")
  const [postingMessage, setPostingMessage] = useState(false)

  const submitMessages = async () => {
    setPostingMessage(true)
    try {
      const newMessage = await postMessages(name, message)
      const newMessageBody = await newMessage.json()
      await mutate(
        "/api/messages",
        [
          {
            ...newMessageBody,
            date: dayjs(newMessageBody.date).format("DD MMMM YYYY"),
          },
          ...(messages || []),
        ],
        { revalidate: false }
      )
      setName("")
      setMessage("")
    } finally {
      setPostingMessage(false)
    }
  }

  return (
    <div className={"pb-16 max-w-7xl mx-auto sm:px-24 2xl:px-0 2xl:-mt-32"}>
      <form
        id={"message-form"}
        className="md:max-w-2xl lg:max-w-lg xl:max-w-md 2xl:max-w-lg mx-auto md:mx-0 p-8 rounded-lg flex flex-col relative xl:sticky top-8 justify-start xl:mb-16"
        onSubmit={async (e) => {
          e.preventDefault()
          const confirmed = confirm(`Post your message as "${name}"?`)
          if (confirmed) {
            await submitMessages()
          }
        }}
      >
        <h2 className={"font-romeo font-bold text-4xl text-romeo-text mb-2"}>
          Guestbook
        </h2>
        <p className={"mb-8 opacity-70"}>Leave us a message! 🎉🎉 </p>
        <TextField
          label={"Name"}
          value={name}
          onChange={(e) => setName(e.target.value)}
          className={`mb-2`}
          autoComplete={"name"}
          required
        />
        <Textarea
          label={"Message"}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          textareaClassName={"h-60"}
          className={"mb-2"}
          required
        />
        <Button
          type={"submit"}
          className={
            "w-full bg-opacity-20 !text-romeo-text disabled:opacity-40"
          }
          disabled={postingMessage}
        >
          {postingMessage && (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-romeo-text"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          )}
          Post Message
        </Button>
      </form>

      {(messages?.length ?? 0) > 0 && (
        <div className={"flex flex-col prose ml-auto p-8 xl:-mt-64"}>
          <h2
            className="font-romeo !text-romeo-text sm:text-center border-b pb-4 sticky top-0 pt-4 sm:pt-16 bg-romeo-background !z-10 flex items-center xl:inline-block"
            id={"messages"}
          >
            Messages
            <a className={"ml-auto p=4 xl:hidden"} href={"#message-form"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
            </a>
          </h2>
          {messages?.map(({ message, name, date, id }) => (
            <article key={id} id={id}>
              <h4 className={"!text-romeo-text"}>{name}</h4>
              {message
                .split("\n")
                .filter((m) => m !== "")
                .map((m) => (
                  <p key={m} className={"!text-romeo-text"}>
                    {m}
                  </p>
                ))}
              <p className={"!text-romeo-text opacity-60 text-sm"}>{date}</p>
            </article>
          ))}
        </div>
      )}
    </div>
  )
}

export default Messages
