import useSWR from "swr"
import { fetcher } from "$lib/network"
import fetch from "node-fetch"

interface Message {
  id: string
  name: string
  message: string
  date: string
}

export const useMessages = () => {
  return useSWR<Message[]>("/api/messages", fetcher, {
    revalidateOnFocus: false,
  })
}

export const postMessages = async (name: string, message: string) => {
  return fetch("/api/messages", {
    method: "POST",
    body: JSON.stringify({
      name,
      message,
    }),
  })
}
