import React, { DetailedHTMLProps, FC, InputHTMLAttributes } from "react"
import clsx from "clsx"

type NativeButtonProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  type: "button" | "submit"
}

const Button: FC<NativeButtonProps> = ({
  children,
  className,
  type,
  ...props
}) => (
  <button
    type={type}
    {...props}
    className={clsx(
      "px-4 py-3 rounded-lg bg-romeo-text text-white text-romeo-text inline-block ml-auto font-bold hover:ring-2 ring-romeo-text ring-offset-2 ring-offset-romeo-background transition duration-200 text-sm flex justify-center",
      className
    )}
  >
    {children}
  </button>
)

export default Button
